<template>
    <div>
        <el-form ref="form" :model="form" label-width="160px">
            <el-form-item label="小票打印机型号：" required>
                <div>
                    <div>飞鹅云打印机（WiFi+USB版）</div>
                    <img src="https://img13.360buyimg.com/n1/jfs/t1/132667/22/10255/94675/5f632513Ebb43d6f3/b17831370436e014.jpg"
                        class="image">
                    <div class="title">确保打印机连接WIFI，方式：</div>
                    <ul class="list">
                        <li>1.确保接通电源，按背面开机键，装好打印纸，顶盖关闭；</li>
                        <li>2.开机状态下，短按设置键；</li>
                        <li>3.再按一下设置键，设备打印“手机APP配置模式”小票；</li>
                        <li>4.根据小票提示操作，微信扫码完成WIFI配置。</li>
                    </ul>
                </div>
            </el-form-item>
            <div class="item" v-for="(item, index) in list" :key="index">
                <el-form-item label="设备备注名称：" required>
                    <el-input v-model="item.remark" class="inputClass" :disabled="item.id"></el-input>
                </el-form-item>
                <el-form-item label="设备机身号：" required>
                    <el-input v-model="item.sn" class="inputClass" :disabled="item.id"></el-input>
                    <el-button type="text" @click="deletePrinter(index)" v-if="list.length > 1 && !item.id"
                        style="margin-left:20px;">删除</el-button>
                </el-form-item>
                <el-form-item label="设备KEY：" required>
                    <el-input v-model="item.key" class="inputClass" :disabled="item.id"></el-input>
                </el-form-item>
            </div>
            <el-button type="text" @click="addPrinter">+添加设备</el-button>
        </el-form>
    </div>
</template>

<script>
export default {
    props: ['list'],
    methods: {
        addPrinter () {
            this.$emit('add-printer')
        },
        deletePrinter (index) {
            this.$emit('delete-printer', index)
        }
    }
}
</script>

<style lang="less" scoped>
.image {
    width: 180px;
    height: 180px;
    border: 1px solid #ccc;
    margin: 8px 0;
}

.title {
    font-weight: bold;
}

.list {
    line-height: 22px;
    margin-top: 5px;
}

.inputClass {
    width: 600px;
}

.el-form-item {
    margin-bottom: 0 !important;

    &+.el-form-item {
        margin-top: 22px !important;
    }
}

.item {
    margin-top: 22px;
    padding: 20px 0;
    background-color: #f1f1f1;
}
</style>
